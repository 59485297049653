import propTypes from 'prop-types'
import React from 'react'
import './EventItem.scss'
import Button from '../button/Button'

const formatDesc = (text = '') =>
  text.length > 250 ? text.substr(0, 250) + ' ...' : text
const formatDate = date => {
  const split = date.split('-')

  return `${split[2]}/${split[1]}/${split[0]}`
}

export default class EventItem extends React.Component {
  static propTypes = {
    data: propTypes.any,
    type: propTypes.string,
  }

  render() {
    const { data, type } = this.props
    return (
      <div className='EventItem'>
        {this.props.data.featured_photo && (
          <div
            className='EventItem__image'
            style={{
              backgroundImage: `url("${data.featured_photo.photo_link}")`,
            }}
          >
            <img alt={data.name} src={require('./assets/img-size.jpg')} />
          </div>
        )}

        <div className='EventItem__content'>
          <div className='EventItem__title bold size-30 uppercase embed-title'>
            {data.name}
          </div>
          <div className='EventItem__date bold size-18 extra-ls uppercase'>
            {formatDate(data.local_date)}
          </div>

          <div className='EventItem__desc size-14 extra-lh light  '>
            {formatDesc(data.plain_text_no_images_description)}
          </div>
          <div className='EventItem__button-wrap  text-right  '>
            <Button
              href={data.link}
              target='_blank'
              label={type === 'past' ? 'VISIT WEBSITE' : 'JOIN MEETUP'}
            />
          </div>
        </div>
      </div>
    )
  }
}
