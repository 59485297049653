import React from 'react'
import './EmptyState.scss'
import propTypes from 'prop-types'

export default class EmptyState extends React.Component {
  static propTypes = {
    text: propTypes.any,
  };
  render() {
    return (
      <div className='EmptyState'>
        <i className='EmptyState__icon far fa-sad-tear size-54' />
        <div className='EmptyState__text size-20 light'>{this.props.text}</div>
      </div>
    )
  }
}
