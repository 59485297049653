import React from 'react'
import Template from '../template/Template'
import EventsPage from '../pageComponents/events/EventsPage'

export default () => {
  return (
    <Template>
      <EventsPage />
    </Template>
  )
}
