import React, { Component } from 'react'
import EventItem from '../../components/eventItem/EventItem.js'
import Loading from '../../components/loading/Loading.js'
import Triangle from '../../components/triangle/Triangle.js'
import EmptyState from '../../components/emptyState/EmptyState'
import Footer from '../../components/footer/Footer'
import './EventsPage.scss'
import fetchJsonp from 'fetch-jsonp'
import SEO from '../../components/seo/Seo.js'
import DynamicText from '../../components/dynamicText/DynamicText'

const FIELDS = 'featured_photo,plain_text_no_images_description'

class EventsPage extends Component {
  state = {
    pastEvents: null,
    upcomingEvents: null,
    loading: false,
    error: null
  }

  componentDidMount() {
    this.loadEvents()
  }

  loadEvents = () => {
    this.setState({ loading: true, error: null })
    const upcoming = fetchJsonp(
      `https://api.meetup.com/${process.env.MEETUP_GROUP}/events?photo-host=public&fields=${FIELDS}&page=20&desc=true&status=upcoming`
    )
      .then((response) => response.json())
      .then((data) => data.data)

    const past = fetchJsonp(
      `https://api.meetup.com/${process.env.MEETUP_GROUP}/events?photo-host=public&fields=${FIELDS}&page=4&desc=true&status=past`
    )
      .then((response) => response.json())
      .then((data) => data.data)

    return Promise.all([upcoming, past])
      .then(([upcomingEvents, pastEvents]) => {
        if (upcomingEvents.errors || pastEvents.errors) {
          throw (upcomingEvents.errors || pastEvents.errors)[0]
        }
        this.setState({ loading: false, upcomingEvents, pastEvents })
      })
      .catch((error) => {
        console.error(error)
        this.setState({ loading: false, error })
      })
  }

  renderEvents = (list, type) => {
    return (
      list &&
      list.map((data, i) => <EventItem type={type} key={i} data={data} />)
    )
  }

  render() {
    const { upcomingEvents, pastEvents, loading, error } = this.state
    return (
      <div>
        <SEO id='events' />
        <div className='EventsPage'>
          <Triangle position='right' />
          <div className='EventsPage__wrap'>
            <div className='container'>
              <div className='size-14 CareersPage__sub-title semi-bold text-center'>
                BLUE HARVEST
              </div>
              <div className='size-54 CareersPage__title embed-title text-center bold'>
                EVENTS
              </div>

              <div className='dynamic-eventsIntro'>
                <DynamicText text='_rawEventsIntro' />
              </div>
              {/* <div className='size-16 extra-lh CareersPage__description'>
              Join us at one of our upcoming events and get to know more about IT from our best software engineers.
              Discover the power of state-of-the-art technologies and learn more about frameworks, architecture models and tools.
              See how these can support the strategy and future of your business.<br /><br />
              We focus on high quality content, bringing you the most inspiring speakers at the best venues.
    </div> */}
              <div className='size-14 EventsPage__sub-title semi-bold text-center'>
                MEETUPS
              </div>
              <div className='EventsPage__title size-34 embed-title text-center bold'>
                UPCOMING EVENTS
              </div>

              {upcomingEvents && upcomingEvents.length === 0 && (
                <EmptyState text='Currently we have no upcoming events' />
              )}

              {loading && <Loading />}
              {error && <EmptyState text={error.message} />}

              {!error && upcomingEvents && upcomingEvents.length > 0 && (
                <div className='EventsPage__list'>
                  <div className='row'>
                    {upcomingEvents &&
                      this.renderEvents(upcomingEvents, 'upcoming')}
                  </div>
                </div>
              )}
              <div className='EventsPage__list'>
                <div className='size-14 EventsPage__sub-title semi-bold text-center'>
                  MEETUPS
                </div>
                <div className='EventsPage__title size-34 embed-title text-center bold'>
                  PAST EVENTS
                </div>
                {error && <EmptyState text={error.message} />}

                <div className='row'>
                  {pastEvents && this.renderEvents(pastEvents, 'past')}
                </div>
              </div>
            </div>
          </div>
          <Triangle position='left' />
        </div>
        <Footer />
      </div>
    )
  }
}

export default EventsPage
