import React from 'react'
import './Loading.scss'

export default class Loading extends React.Component {
  render() {
    return (
      <div className='Loading size-40'>
        <span />
      </div>
    )
  }
}
